import {defineStore} from 'pinia';

export const useGallery = defineStore('gallery', {
  state: () => ({
    currentImage: 0,
  }),
  actions: {
    setCurrentImage(img: number) {
      this.currentImage = img;
    },
  },
})
