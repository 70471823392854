






















import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { PropType } from 'vue';
import { Product } from '~/modules/catalog/product/types';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import AddToWishlist from '~/bbrTheme/components/AddToWishlist.vue';
import useMagentoConfiguration from '~/composables/useMagentoConfiguration';

export default defineComponent({
  name: 'ProductInfoData',
  components: {
    AddToWishlist,
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },

  },
  setup(props) {
    const { isAuthenticated } = useUser();
    const { addOrRemoveItem, isInWishlist } = useWishlist();
    const { isWishlistEnabled } = useMagentoConfiguration();
    const isItemInWishlist = computed(() => isInWishlist({ product: props.product }))

    return {
      isAuthenticated,
      addOrRemoveItem,
      isItemInWishlist,
      isWishlistEnabled,
    }
  },
})
