



















import { defineComponent } from '@nuxtjs/composition-api';
import { useMagentoConfiguration } from '~/composables';

export default defineComponent({
  name: 'CartFreeDeliveryInfo',
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    target: {
      type: String,
      default: '_self',
    },
    deliveryUrl: {
      type: String,
      default: '/services/delivery-free',
    },
    deliveryAmount: {
      type: Number,
      default: 200,
    },
  },
  setup() {
    const {
      selectedCurrency,
      selectedLocale,
    } = useMagentoConfiguration();

    return {
      selectedLocale,
      currencyOptions: {
        style: 'currency',
        currency: selectedCurrency.value,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      },
    };
  },
})
