

















import { computed, defineComponent } from '@nuxtjs/composition-api';
import { PoiInterface, RenderPoiSource } from '~/modules/amplience/types';
import ArticleCard from '~/modules/amplience/components/atoms/ArticleCard.vue';

export default defineComponent({
  name: 'ArticleCardRow',
  components: {
    ArticleCard,
  },
  props: {
    articleRow: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const articleLink = (articlePage): string => {
      const deliveryKey = articlePage?.deliveryKey;

      return deliveryKey ? `/${deliveryKey}` : '';
    };

    return {
      poi: (article): PoiInterface | any => article?.articleImagePoi,
      title: (article): string => article?.articleTitle || '',
      categoryId: (article): string => article?.articleCategory[0]?.id || '',
      articleLink,
      sources: computed(() => ([
        {
          aspect: '1.3:1',
          height: 280,
          media: '(max-width: 767px)',
        },
        {
          aspect: props.articleRow.length === 2 ? '88:49' : '1:1',
          height: 294,
          media: '(min-width: 768px)',
        },
      ] as RenderPoiSource[])),
    };
  },
});
