import { render, staticRenderFns } from "./RichText.vue?vue&type=template&id=1f2d6ff2&scoped=true"
import script from "./RichText.vue?vue&type=script&lang=ts"
export * from "./RichText.vue?vue&type=script&lang=ts"
import style0 from "./RichText.vue?vue&type=style&index=0&id=1f2d6ff2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f2d6ff2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RenderRichText: require('/var/www/modules/amplience/components/render/RichText.vue').default,SkeletonRichText: require('/var/www/modules/amplience/components/skeleton/RichText.vue').default})
