












import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useAuth } from '~/modules/azure-b2c-auth/composables/useAuth';

export default defineComponent({
  name: 'AddToWishlist',
  props: {
    component: {
      type: String,
      default: 'div',
    },
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { login } = useAuth();
    const actionText = computed(() => (props.isInWishlist ? 'Remove from wishlist' : 'Add to wishlist'));

    const handleClick = async () => {
      if (props.isAuthenticated) {
        emit('addToWishlist');
      } else {
        await login();
      }
    };

    return {
      actionText,
      handleClick,
    };
  },
});
