import axios from 'axios';
import { useContext, ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseMediaSet, Content } from '@amplience/composables/useMediaSet/useMediaSet';

export function useMediaSet(): UseMediaSet {
  const loading = ref<boolean>(false);
  const content = ref<Content>([]);
  const { $amplience } = useContext();

  const search = async (mediaSetId: string): Promise<Content> => {
    try {
      loading.value = true;

      const { data } = await axios.get(`${$amplience.mediaSetUrl}${mediaSetId}.json`, {
        params: {
          metadata: true,
          protocol: 'https',
        },
      });

      content.value = data?.items || [];
    } catch (error) {
      Logger.error('Cannot get Media Set from Amplience.', error.message)
    }

    loading.value = false;

    return content.value;
  };

  return {
    content,
    search,
  };
}
