import { ref, useContext } from '@nuxtjs/composition-api';
import type { Product } from '~/modules/catalog/product/types';
import {
  BBRProductAttributes,
  getAttributeValue,
  getRoundelUrlCode,
} from '@theme/modules/catalog/getters/productGetters';
import { getName } from '~/modules/catalog/product/getters/productGetters';
import { useMediaSet } from '@amplience/composables/useMediaSet';

export function useProductGallery(product: Product, imgPlaceholder = ''): any {
  const ctx = useContext();
  const { search } = useMediaSet();
  const defaultImg = ctx.$getAmplienceImageUrl(product, 'none');
  const productGallery = ref<any[]>([]);
  const imageSizes = {
    productCard: {
      width: 241,
      height: 380,
    },
    productCardHorizontal: {
      width: 140,
      height: 200,
    },
    checkout: {
      imageWidth: 100,
      imageHeight: 100,
    },
    productGallery: {
      thumbWidth: 60,
      thumbHeight: 60,
      imageWidth: 388,
      imageHeight: 585,
    },
    cart: {
      imageWidth: 170,
      imageHeight: 170,
    },
  };

  const applyRoundel = (img: string, roundelCode?: string): string => {
    return `${img}${roundelCode ? `?layer1=[${roundelCode}]&fmt=auto` : '?fmt=auto'}`;
  };

  const getGallery = async (): Promise<any> => {
    const mainImage = getAttributeValue(product, BBRProductAttributes.MAIN_IMAGE);
    const roundelCode = getRoundelUrlCode(product);
    const selectedGallery = await search(`${mainImage}-ms`);

    if (selectedGallery.length) {
      productGallery.value = selectedGallery.map((img: any) => ({
        mobile: { url: applyRoundel(img.src, roundelCode) },
        desktop: { url: applyRoundel(img.src, roundelCode) },
        big: { url: applyRoundel(img.src, roundelCode) },
        alt: img.metadata?.image_alt_data?.alt_text || getName(product) || ctx.i18n.t('Product name'),
        placeholder: imgPlaceholder,
      }));
    } else {
      productGallery.value = [{
        mobile: { url: defaultImg },
        desktop: { url: defaultImg },
        big: { url: defaultImg },
        alt: getName(product) || ctx.i18n.t('Product name'),
        placeholder: imgPlaceholder,
      }]
    }
  };

  return {
    productGallery,
    getGallery,
    imageSizes,
  };
}

export default useProductGallery;
export * from './useProductGallery';
