





























import { defineComponent, onMounted, toRef } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { Product } from '~/modules/catalog/product/types';
import { useProductGallery } from '@theme/modules/catalog/product/composables/useProductGallery';
import Gallery from '@theme/modules/catalog/components/Gallery.vue';
import { SfSkeleton } from '@storefront-ui/vue';
import { useProductDetails } from '@theme/modules/catalog/stores/product';
import { getProductLabels } from '@theme/modules/catalog/getters/productGetters';
import { storeToRefs } from 'pinia';
import ProductLabels from '@theme/modules/catalog/components/molecules/ProductLabels.vue';

export default defineComponent({
  name: 'ProductGallery',
  components: {
    Gallery,
    SfSkeleton,
    ProductLabels,
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const product = toRef(props, 'product');
    const { productGallery, imageSizes, getGallery } = useProductGallery(product.value);
    const productDetails = useProductDetails();
    const { isEvent } = storeToRefs(productDetails);

    onMounted(async () => {
      await getGallery();
    });

    return {
      getProductLabels,
      productGallery,
      imageSizes,
      isEvent,
    }
  }
});
