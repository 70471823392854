//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  SfImage,
} from '@storefront-ui/vue';
import { onMounted, defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { register } from 'swiper/swiper-element-bundle.mjs';
import { useGallery } from '~/bbrTheme/modules/catalog/stores/gallery';
import { storeToRefs } from 'pinia';
register();

export default defineComponent({
  name: 'Gallery',
  components: {
    SfImage,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    imageWidth: {
      type: [Number, String],
      default: null,
    },
    imageHeight: {
      type: [Number, String],
      default: null,
    },
    thumbWidth: {
      type: [Number, String],
      default: null,
    },
    thumbHeight: {
      type: [Number, String],
      default: null,
    },
    enableZoom: {
      type: Boolean,
      default: false,
    },
    imageTag: {
      type: String,
      default: 'img',
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    thumbImageTag: {
      type: String,
      default: 'img',
    },
    thumbNuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const positionStatic = ref({});
    const isMouseDown = ref(false);
    const store = useGallery();
    const { currentImage: activeIndex } = storeToRefs(store);
    const style = ref('');
    const pictureSelected = ref(props.images[0] || {
      alt: '',
      zoom: '',
      big: {
        url: '',
      },
      desktop: '',
      placeholder: '',
    });
    const isZoomStarted = ref(false);
    const imgZoom = ref(null);
    const sfGalleryBigImage = ref(null);
    const outSide = ref(null);
    const lastTouchX = ref(null);
    const lastTouchY = ref(null);
    const movementX = ref(0);
    const movementY = ref(0);
    const slider = ref(null);

    const mapPictures = computed(() => {
      // Map images to handle picture tags with SfImage
      return props.images.map(({desktop, big}) => ({
        mobile: desktop,
        desktop: big,
      }));
    });

    const definedPicture = computed(() => {
      const {zoom, big, desktop} = pictureSelected.value;
      const definedPicture = zoom || big || desktop;

      if (definedPicture) {
        definedPicture.alt = pictureSelected.value?.alt || 'imageAlt';
        definedPicture.placeholder = pictureSelected.value?.placeholder;
      }

      return definedPicture || '';
    });

    const positionObject = (index) => {
      if (sfGalleryBigImage.value) {
        return sfGalleryBigImage.value[index].$el.getBoundingClientRect();
      }

      return '';
    };

    const onSlideChange = (event) => {
      const prevIndex = event.detail[0].previousIndex;

      if (prevIndex >= 0 && prevIndex < props.images.length) {
        store.setCurrentImage(event.detail[0].activeIndex);
        sfGalleryBigImage.value[prevIndex].$el.children[0].style.transform = 'scale(1)';
        sfGalleryBigImage.value[prevIndex].$el.children[0].style.cssText = '';
        slider.value.swiper.allowTouchMove = true;
        isZoomStarted.value = false;
      }
    }

    const toggleZoom = (event, index) => {
      event.preventDefault();
      isZoomStarted.value = !isZoomStarted.value;
      movementX.value = 0;
      movementY.value = 0;
      lastTouchX.value = null;
      lastTouchY.value = null;

      if (props.enableZoom && isZoomStarted.value) {
        slider.value.swiper.allowTouchMove = false;
        sfGalleryBigImage.value[index].$el.children[0].style.cssText =
          'top: 0; ' +
          'transform: scale(2.5); ' +
          'position: absolute !important;';
      } else {
        slider.value.swiper.allowTouchMove = true;
        sfGalleryBigImage.value[index].$el.children[0].style.transform = 'scale(1)';
        sfGalleryBigImage.value[index].$el.children[0].style.cssText = '';
      }
    }

    const moveZoom = (event, index) => {
      if (props.enableZoom && isMouseDown.value && isZoomStarted.value) {
        event.preventDefault();
        positionStatic.value = positionObject(index);
        sfGalleryBigImage.value[index].$el.children[0].style.cssText =
          'top: 0; ' +
          'transform: scale(2.5); ' +
          'position: absolute !important;';

        if (event.type === 'mousemove') {
          movementX.value += event.movementX;
          movementY.value += event.movementY;

          sfGalleryBigImage.value[index].$el.children[0].style.left =
            `${sfGalleryBigImage.value[index].$el.children[0].offsetLeft + movementX.value}px`;
          sfGalleryBigImage.value[index].$el.children[0].style.top =
            `${sfGalleryBigImage.value[index].$el.children[0].offsetTop + movementY.value}px`;
        }

        if (event.type === 'touchmove') {
          const touch = event.touches[0];
          lastTouchX.value = lastTouchX.value === null ? touch.clientX : lastTouchX.value;
          lastTouchY.value = lastTouchY.value === null ? touch.clientY : lastTouchY.value;
          movementX.value += touch.clientX - lastTouchX.value;
          movementY.value += touch.clientY - lastTouchY.value;

          sfGalleryBigImage.value[index].$el.children[0].style.left =
            `${sfGalleryBigImage.value[index].$el.children[0].offsetLeft + movementX.value}px`;
          sfGalleryBigImage.value[index].$el.children[0].style.top =
            `${sfGalleryBigImage.value[index].$el.children[0].offsetTop + movementY.value}px`;

          lastTouchX.value = touch.clientX;
          lastTouchY.value = touch.clientY;
        }
      }
    };

    const endMoveZoom = () => {
      if (props.enableZoom && isMouseDown.value && isZoomStarted.value) {
        lastTouchX.value = null;
        lastTouchY.value = null;
        isMouseDown.value = false;
      }
    }

    const onResize = () => {
      slider.value.swiper.activeIndex = activeIndex.value;
    }

    const onThumbnailChange = (index) => {
      slider.value.swiper.activeIndex = index;
      activeIndex.value = index;
      slider.value.swiper.update();
    };

    onMounted(() => {
      const params = {
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
        thumbs: {
          swiper: '.sf-gallery__thumbs'
        },
        navigation: 'true',
        injectStyles: [
          `
          .swiper-button-next svg,
          .swiper-button-prev svg {
            display: none;
          }
          .swiper-button-next {
            right: var(--swiper-navigation-sides-offset,16px);
          }
          .swiper-button-prev {
            left: var(--swiper-navigation-sides-offset,16px);
          }
          .swiper-button-disabled {
            display: none;
          }
          .swiper-button-next:before {
            content: var(--icon-action-arrows-right);
            color: var(--c-neutral-800);
            font-family: 'bbr-icons';
            font-size: 48px;
          }
          .swiper-button-prev:before {
            content: var(--icon-action-arrows-left);
            color: var(--c-neutral-800);
            font-family: 'bbr-icons';
            font-size: 48px;
          }
          .swiper-button-next,
          .swiper-button-prev {
            background: var(--c-neutral-100);
            width: var(--spacer-2xl);
            height: var(--spacer-2xl);
            border-radius: 50%;
          }
        `,
        ],
      };
      Object.assign(slider.value, params);
      slider.value.initialize();
    });

    return {
      onResize,
      endMoveZoom,
      slider,
      onThumbnailChange,
      onSlideChange,
      toggleZoom,
      positionStatic,
      isMouseDown,
      activeIndex,
      style,
      pictureSelected,
      isZoomStarted,
      mapPictures,
      definedPicture,
      positionObject,
      moveZoom,
      outSide,
      imgZoom,
      sfGalleryBigImage
    };
  },
});
