









import { defineComponent, useFetch } from '@nuxtjs/composition-api';
import { useProductDetails } from '~/bbrTheme/modules/catalog/stores/product';
import { storeToRefs } from 'pinia';
import { useContent } from '~/modules/amplience/composables/useContent';
import USPDefaultInfo from './DefaultInfo.vue';

export default defineComponent({
  name: 'USPInfo',
  components: {
    USPDefaultInfo,
  },
  setup() {
    const store = useProductDetails();
    const { code } = storeToRefs(store);
    const { search, data } = useContent();

    useFetch(async () => {
      if (code.value) {
        await search(`usp_${code.value}`, 'url');
      }
    });

    return {
      data
    }
  }
});
