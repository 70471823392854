import { render, staticRenderFns } from "./Recipe.vue?vue&type=template&id=61801d00&scoped=true"
import script from "./Recipe.vue?vue&type=script&lang=ts"
export * from "./Recipe.vue?vue&type=script&lang=ts"
import style0 from "./Recipe.vue?vue&type=style&index=0&id=61801d00&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61801d00",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RenderPOI: require('/var/www/modules/amplience/components/render/POI.vue').default,RenderRecipe: require('/var/www/modules/amplience/components/render/Recipe.vue').default})
