const { getCookies, getResponseCookie } = require('./cookies');

/**
 * Check feature-dual-running boolean cookie for Dual Running mode.
 *
 * Defaults to true. Handles both cookie object and array.
 * File must be CommonJS as it's used in other CommonJS files.
 */
module.exports.isDualRunning = (cookies, cookiesArray = null) => {
  const dualRunning = cookiesArray
    ? cookiesArray['feature-dual-running']
    : cookies.get('feature-dual-running');

  /**
   * This line returns false if VSF_DUAL_RUNNING_ENABLED_DEFAULT is not set to strictly true.
   *
   * TODO: Invert the check from '=== true' to '!== false' for R1 launch, so that this line falls back to true.
   *       So that this code returns true unless explicitly set to false.
   */
  const DEFAULT = process.env.VSF_DUAL_RUNNING_ENABLED_DEFAULT === 'true';

  return dualRunning ?? DEFAULT;
};

/**
 * Check hybris-customer cookie to check if the user is logged in
 */
module.exports.isLoggedIn = (cookies) => !!cookies.get('hybris-customer');

/**
 * Set hybris-customer cookie
 */
module.exports.setIsLoggedIn = (cookies, value) => cookies.set('hybris-customer', value);

/**
 * Given a request object, Hybris cookies are picked and set on the headers
 */
module.exports.getHybrisHeaders = (expressRequest) => {
  const cookies = getCookies(expressRequest);

  const cookiesToPick = [
    'feature-dual-running',
    'JSESSIONID',
    'INGRESSCOOKIE',
    'acceleratorSecureGUID',
    'bbrstorefrontRememberMe',
    'X-CSRF-Token'
  ];

  const headers = {
    'Cookie': '',
  };

  if (!('feature-dual-running' in cookies)) {
    cookies['feature-dual-running'] = process.env.VSF_DUAL_RUNNING_ENABLED_DEFAULT === 'true';
  }

  Object.keys(cookies).forEach(name => {
    if (cookiesToPick.includes(name)) {
      headers['Cookie'] = headers['Cookie'].concat(`${name}=${cookies[name]};`);
    }
  });

  // This key in cookies is case-sensitive
  if (cookies['X-CSRF-Token']) {
    headers['X-CSRF-Token'] = cookies['X-CSRF-Token'];
  }

  return headers;
};

/**
 * Check if the JSESSIONID cookie sent in the request is different from the response Set-Cookie header
 * If it is, set a cookie to force the user to log in again, as this indicates a session transfer
 */
module.exports.checkSessionTransfer = (expressRequest, axiosResponse, expressResponse) => {
  const requestJID = getCookies(expressRequest)['JSESSIONID'];
  const responseJID = getResponseCookie(axiosResponse.headers, 'JSESSIONID');

  if (requestJID !== responseJID) {
    expressResponse.append('Set-Cookie', 'hybris-force-login=true; Path=/');
  }
};

/**
 * Process Hybris error, return 302 if the error is due to session expiration / authentication
 */
module.exports.processHybrisError = (axiosError, shouldThrow = true) => {
  switch (axiosError.response.status) {
    case 302:
      if (axiosError.response.headers['location']?.includes('invalidSession')) {
        return {
          message: 'Due to inactivity, you have been logged out. Please log in again.',
          status: 302,
        };
      }

    case 401:
    case 403:
      return {
        message: 'Due to inactivity, you have been logged out. Please log in again.',
        status: 302,
      }

    default:
      if (shouldThrow) {
        throw new Error(axiosError);
      } else {
        return {
          errors: axiosError.response.data.errors,
        };
      }
  }
}
