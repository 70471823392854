










































import { defineComponent } from '@nuxtjs/composition-api';
import USPIcon from '~/bbrTheme/components/atoms/USPIcon.vue';
import CartFreeDeliveryInfo from '~/modules/checkout/components/CartFreeDeliveryInfo.vue'

export default defineComponent({
  name: 'USPDefaultInfo',
  components: {
    USPIcon,
    CartFreeDeliveryInfo,
  },
});
