

























import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import { BodyImageInterface, RenderPoiSource } from '~/modules/amplience/types';

export default defineComponent({
  name: 'BodyImage',
  props: {
    items: {
      type: Array as PropType<BodyImageInterface[]>,
      default: () => [],
    },
    sources: {
      type: Array as PropType<RenderPoiSource[]>,
      default: () => [],
    },
  },
  setup(props) {
    const isImageHolder = (item: BodyImageInterface): boolean => {
      return !!(item.imageHolder?.poi?.image);
    };

    const computedSources = computed(() => props.sources.length
      ? props.sources
      : [{
           aspect: props.items.length === 1 ? '544:242' : '382:347',
           height: props.items.length === 1 ? 242 : 494,
           media: '(max-width: 576px)',
         },
         {
           aspect: props.items.length === 1 ? '960:427' : '720:658',
           height: props.items.length === 1 ? 427 : 877,
           media: '(min-width: 577px) and (max-width: 992px)',
         },
         {
           aspect: props.items.length === 1 ? '1300:480' : '531:480',
           height: 480,
           media: '(min-width: 993px)',
         }]
    );

    return {
      isImageHolder,
      computedSources,
    };
  }
});
